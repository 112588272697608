html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #fff;
}

mat-dialog-container#modal-component {
  border: 3px solid gray;
  border-radius: 11px;
  background-color: whitesmoke;
}

.marker-cluster-red {
  background-color: rgba(255, 0, 0, 0.3);
}

.marker-cluster-red div {
  background-color: rgba(255, 0, 0, 0.4);
}

.marker-cluster-orange {
  background-color: rgba(255, 165, 0, 0.4);
}

.marker-cluster-orange div {
  background-color: rgba(255, 165, 0, 0.9);
}

.marker-cluster-blue {
  background-color: rgba(0, 0, 255, 0.3);
}

.marker-cluster-blue div {
  background-color: rgba(0, 0, 255, 0.4);
}
